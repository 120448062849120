<template lang="html">
  <div>
    <vs-row class="pt-6">
      <vs-col vs-w="6" vs-type="flex">
        <vs-button color="primary" type="gradient" icon-pack="material-icons" icon="fast_rewind"
                   @click="$router.push({name: 'roles'}).catch(() => {})"> {{$t('Roles')}}
        </vs-button>
      </vs-col>
    </vs-row>
    <vs-row class="pt-12">
      <vs-col>
        <template>
          <div id="data-list-list-view" class="data-list-container">
            <vs-table search max-items="5" pagination :data="trashedRoles">
              <template slot="header">
                <h3>{{$t('Roles')}}</h3>
              </template>
              <template slot="thead">
                <vs-th>{{$t('Id')}}</vs-th>
                <vs-th>{{$t('Role')}}</vs-th>
                <vs-th>{{$t('Actions')}}</vs-th>
              </template>
              <template slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="tr">
                    <vs-chip color="primary">
                      {{ tr.id }}
                    </vs-chip>
                  </vs-td>
                  <vs-td :data="tr">
                    {{ tr.name }}
                  </vs-td>
                  <vs-td :data="tr">
                    <vs-button color="primary" type="border" @click="restoreRole(tr.id)">{{$t('Restore')}}</vs-button>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </template>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "TrashedRoles",
  data() {
    return {}
  },
  methods: {
    getData(){
      this.$store.dispatch('role/getTrashed')
    },
    restoreRole(roleId) {
      this.$store.dispatch('role/restoreRole', roleId)
    }
  },
  computed: {
    trashedRoles() {
      return this.$store.getters["role/trashed"]
    }
  },
  created() {
    this.getData()
  }
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }
  }
}
</style>
